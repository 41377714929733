import React, { Component, Fragment } from 'react'
import { navigate } from 'gatsby'

import ReactModal from 'react-modal'
import Loadable from 'react-loadable'
import posed from 'react-pose'

import BetterIcon from '@/components/utils/BetterIcon'
import messageIcon from '@/icons/ui-message.svg'
import recordIcon from '@/icons/ui-record.svg'
import Loader from '@/components/utils/Loader'

import ContactForm from './ContactForm'

ReactModal.setAppElement('#___gatsby')

const LoadableComponent = Loadable({
	loader: () => import('@/components/Recorder/RecorderUi'),
	loading: () => <Loader />
})

export const FadeBox = posed.div({
	exit: { opacity: 0, x: '100%' },
	entering: { x: '100%', opacity: 0 },
	enter: {
		opacity: 1,
		x: 0,
		transition: { ease: 'easeOut' },
		onComplete: () => {
			window.scrollTo(0, 0)
		}
	}
})

export default class Form extends Component {
	state = {
		showRecorder: false,
		recording: false,
		download: false
	}

	saveAudio = (recording, download) => {
		this.setState({
			showRecorder: false,
			recording,
			download
		})
	}

	render() {
		const {
			prisons,
			recorderBg,
			image,
			body,
			about,
			recommendContent
		} = this.props
		const { showRecorder, recording, download } = this.state

		const allowRecorder =
			typeof window !== 'undefined' &&
			(typeof navigator !== 'undefined' && typeof navigator.mediaDevices !== 'undefined' ) &&
			window.AudioContext

		return (
			<Fragment>
				{!recording && (
					<div className="mb-2 md:flex md:flex-wrap md:-ml-1">
						<div className="md:pl-1 mb-1 md:hidden">
							<button
								className="font-bold px-1 text-white bg-teal flex items-center w-full h-65 text-ms--1 md:text-ms-1 hover:bg-orange trans"
								type="button"
								onClick={() => {
									const { top } = this.form.current.getBoundingClientRect()
									window.scrollTo({
										top: top + window.pageYOffset,
										left: 0,
										behavior: 'smooth'
									})
								}}
							>
								<BetterIcon icon={messageIcon} className="w-30 mr-1" />
								write your message
							</button>
						</div>
						{allowRecorder &&
							(
								<div className="md:pl-1 md:w-auto">
									<button
										className="font-bold px-1 text-white bg-orange flex items-center w-full h-65 text-ms--1 md:text-ms-1 hover:bg-teal trans lg:whitespace-no-wrap"
										type="button"
										onClick={() => {
											this.setState({ showRecorder: true })
										}}
									>
										<BetterIcon icon={recordIcon} className="w-20 mr-1" />
										record a voice message
									</button>
								</div>
							)
						}
					</div>
				)}

				<ContactForm
					image={image}
					body={body}
					about={about}
					recommendContent={recommendContent}
					prisons={prisons}
					recording={recording}
					download={download}
				/>
				<ReactModal
					overlayClassName="perf-fixed pin md:flex md:items-center md:justify-center z-30"
					style={{
						overlay: {
							backgroundColor: 'rgba(0,0,0,0.8)'
						},
						content: {
							borderRadius: 0,
							border: 0,
							top: 'auto',
							bottom: 'auto',
							right: 'auto',
							left: 'auto'
						}
					}}
					className="w-full md:w-12/24 outline-none md:px-1-5 md:mx-auto"
					closeTimeoutMS={300}
					onRequestClose={() => {
						this.setState({ showRecorder: false })
					}}
					isOpen={showRecorder}
				>
					<LoadableComponent
						saveAudio={this.saveAudio}
						image={recorderBg}
						goBack={() => {
							this.setState({ showRecorder: false })
						}}
					/>
				</ReactModal>
			</Fragment>
		)
	}
}
