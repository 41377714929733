/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react'
import validate from 'validate.js'

const validateInput = (e, { onChange, name, rule }) => {
	const {
		target: { value }
	} = e

	const errors = validate.single(value, rule)

	const valid = typeof errors === 'undefined'
	const message = valid ? '' : errors[0]
	onChange(name, { value, valid, message })
}

export class Field extends Component {
	onUpdateValidate = e => {
		validateInput(e, this.props)
	}

	render() {
		const {
			text,
			id,
			type,
			name,
			onChange,
			onBlur,
			value,
			touched,
			error
		} = this.props

		return (
			<label
				htmlFor={name}
				className="md:w-12/24 md:px-0-5 relative mb-1-25 lg:mb-0 block"
			>
				<span className="font-bold block mb-0-5">{text}</span>
				<input
					className={`block w-full appearance-none trans bg-white-30 px-0-5 py-0-5 border text-white ${
						error && touched
							? 'border-error focus:shadow-error'
							: 'border-transparent focus:shadow-teal'
					}`}
					type={type}
					id={id}
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				/>
				{error && touched && (
					<div className="absolute font-bold pt-0-25 pin-b mt-1 y-100 text-ms--4 text-error">
						{error}
					</div>
				)}
			</label>
		)
	}
}

export class Textarea extends Component {
	onUpdateValidate = e => {
		validateInput(e, this.props)
	}

	render() {
		const {
			text,
			id,
			name,
			value,
			error,
			touched,
			onChange,
			onBlur
		} = this.props
		return (
			<label className="relative block" htmlFor={id}>
				<span className="font-bold block mb-0-5">{text}</span>
				<textarea
					className={`block  trans w-full appearance-none rounded-none bg-white-30 border text-white p-0-5 md:p-1 outline-none ${
						error && touched
							? 'border-error focus:shadow-error'
							: 'border-transparent focus:shadow-teal'
					}`}
					id={id}
					name={name}
					rows="7"
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				/>
				{error && touched && (
					<div className="absolute font-bold pt-0-25 pin-b mt-1 y-100 text-ms--4 text-error">
						{error}
					</div>
				)}
			</label>
		)
	}
}
