import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import SEO from '@/components/SEO/SEO'

/* eslint-disable no-unused-vars */
import {
	optimisedImage,
	relatedBlog,
	relatedAudio,
	relatedVideo,
	previewThumb
} from '@/queries/'
/* eslint-enable */
import PageHeader from '@/components/PageHeader/PageHeader'
import Form from '@/components/Form/Form'
import { camelCaseToDash } from '@/utils/strings'
import { setupPage } from '@/utils/common'
import { prepItem, getSeoImage } from '@/utils'

class RequestASong extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			data: {
				request: { entry },
				prisons: {
					globals: {
						prisons: { prison }
					}
				},
				thanks: {
					globals: {
						thanks: {
							body: { content: thanks },
							recommendContent
						}
					}
				}
			},
			location
		} = this.props

		const prisons = prison.map(({ prison }) => ({
			value: prison,
			key: camelCaseToDash(prison)
		}))

		const img = entry.image[0]

		const image = {
			...img.optimisedImage,
			title: img.title
		}

		const rImg = entry.recorderBackgroundImage[0]

		const recorderBg = {
			...rImg.optimisedHero,
			title: img.title
		}

		const header = {
			title: entry.title,
			image,
			body: entry.subtitle,
			about: entry.aboutPrisonRadio.content
		}

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription,
			subtitle
		} = entry
		const seoImage = getSeoImage(entry)

		return (
			<Fragment>
				<SEO
					title={seoTitle || entry.title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || entry.title}
					socialDesc={socialMetaDescription || subtitle}
					image={seoImage}
					url={location.href}
				/>

				<PageHeader {...header} />

				<div className="wrapper">
					<div className="mx-auto lg:w-12/24">
						<Form
							prisons={prisons}
							title="thanks"
							image={image}
							body={thanks}
							about={header.about}
							recorderBg={recorderBg}
							recommendContent={prepItem(recommendContent)}
						/>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(RequestASong)

export const pageQuery = graphql`
	query RequestASongQuery {
		request: craft {
			entry(id: 5) {
				... on Craft_LandingPagesRequestForm {
					title
					subtitle
					aboutPrisonRadio {
						content
					}
					image {
						title
						... on Craft_ImagesVolume {
							optimisedImage {
								... on Craft_OptimizedImagesData {
									src
									srcset
									srcWebp
									srcsetWebp
									maxSrcsetWidth
									placeholderWidth
									placeholderHeight
									colorPalette
								}
							}
						}
					}

					seoTitle
					seoDescription
					socialMetaTitle
					socialMetaDescription
					socialMetaImage {
						... on Craft_ImagesVolume {
							...optimisedImage
						}
					}

					recorderBackgroundImage {
						title
						... on Craft_HeroVolume {
							optimisedHero {
								... on Craft_OptimizedImagesData {
									src
									srcset
									srcWebp
									srcsetWebp
									maxSrcsetWidth
									placeholderWidth
									placeholderHeight
									colorPalette
								}
							}
						}
					}
					seo {
						title
						description

						social {
							twitter {
								title
								image {
									url
								}
								description
							}
							facebook {
								title
								image {
									url
								}
								description
							}
						}
					}
				}
			}
		}

		prisons: craft {
			globals {
				prisons {
					... on Craft_Prisons {
						prison {
							prison
						}
					}
				}
			}
		}

		thanks: craft {
			globals {
				thanks {
					body {
						content
					}
					recommendContent {
						__typename
						...relatedBlog
						...relatedAudio
						...relatedVideo
					}
				}
			}
		}
	}
`

/*


*/
