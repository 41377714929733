/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control, jsx-a11y/role-has-required-aria-props */
import React, { Component } from 'react'
import Downshift from 'downshift'
import chevronDown from '@/icons/ui-chevron-down.svg'
import Icon from '@/components/utils/Icon'
import { advancedFilter } from '@/utils'

export default class Select extends Component {
	render() {
		const {
			items,
			label,
			placeholder,
			// onChange = noop,
			name,
			error,
			touched,
			setFieldValue
		} = this.props

		return (
			<Downshift
				onChange={({ value }) => {
					setFieldValue(name, { value, valid: value !== '' })
				}}
				itemToString={item => (item ? item.value : '')}
			>
				{({
					getInputProps,
					getItemProps,
					getLabelProps,
					getMenuProps,
					isOpen,
					inputValue,
					highlightedIndex,
					selectedItem,
					getToggleButtonProps
				}) => (
					<div className="relative z-30">
						{label && (
							<label className="font-bold block mb-0-5" {...getLabelProps()}>
								{label}
							</label>
						)}
						<div className="relative">
							<input
								className={`block  w-full bg-white outline-none trans px-0-5 py-0-5 text-black border ${
									error && touched
										? 'border-error focus:shadow-error'
										: 'border-transparent focus:shadow-teal'
								}`}
								{...getInputProps()}
								placeholder={placeholder}
								name={name}
							/>
							<button
								{...getToggleButtonProps({
									'data-testid': 'semantic-ui-toggle-button'
								})}
								type="button"
								className="absolute pin-y flex items-center pin-r mr-1 text-teal"
							>
								<Icon icon={chevronDown} />
							</button>
							{error && touched && (
								<div className="absolute pt-0-25 pin-b mt-1 y-100 text-ms--4 text-error">
									{error}
								</div>
							)}
						</div>
						<ul
							className="list-reset absolute w-full pin-l max-h-300 overflow-auto"
							{...getMenuProps()}
						>
							{isOpen
								? (inputValue ? advancedFilter(items, inputValue) : items).map(
										(item, index) => (
											<li
												role="option"
												className="text-black px-1 py-0-5"
												{...getItemProps({
													key: item.key,
													index,
													item,
													style: {
														backgroundColor:
															highlightedIndex === index
																? 'lightgray'
																: 'white',
														fontWeight:
															selectedItem === item ? 'bold' : 'normal'
													}
												})}
											>
												{item.value}
											</li>
										)
								  )
								: null}
						</ul>
					</div>
				)}
			</Downshift>
		)
	}
}
