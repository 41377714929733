import React, { Component, createRef } from 'react'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import { ScaleLoader } from 'react-spinners'
import Select from '@/components/Select/Select'
import Button from '@/components/Button/Button'
import rightArrowIcon from '@/icons/ui-right-arrow.svg'
import Icon from '@/components/utils/Icon'
import tick from '@/icons/ui-tick.svg'
import BetterIcon from '@/components/utils/BetterIcon'
import { Field, Textarea } from './FormElements'

export default class ContactForm extends Component {
	form = createRef()

	fileInput = createRef()

	render() {
		const {
			prisons,
			image,
			body,
			about,
			recommendContent,
			recording,
			download
		} = this.props

		return (
			<Formik
				initialValues={{
					prison_number: '',
					prisoner_name: '',
					prison: '',
					name: '',
					email: '',
					artist: '',
					song: '',
					message: '',
					confirm_permissions: false
				}}
				validate={values => {
					const errors = {}
					if (!values.prison_number) {
						errors.prison_number = 'Please enter the prisoner number'
					}

					if (!values.prisoner_name) {
						errors.prisoner_name = 'Please enter the prisoner name'
					}
					if (!values.prison) {
						errors.prison = 'Please enter the prison'
					}
					if (!values.name) {
						errors.name = 'Please enter your name'
					}

					if (!values.email) {
						errors.email = 'Please enter your email address'
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
					) {
						errors.email = 'Please enter a valid email address'
					}

					if (!values.artist && !recording) {
						errors.artist = 'Please enter an artist'
					}
					if (!values.song && !recording) {
						errors.song = 'Please enter a song'
					}
					if (!values.message && !recording) {
						errors.message = 'Please enter a message'
					}
					if (!values.confirm_permissions) {
						errors.confirm_permissions = 'You need to check the checkbox'
					}

					return errors
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true)
					const formData = new FormData(this.form.current)

					if (recording) {
						formData.append('audio-filename', recording.name)
						formData.append('audio-blob', recording)
						// formData.append('name', values.name)
						// formData.append('email', values.email)
					}

					// const formBody = Array.from(formData, e =>
					// 	e.map(encodeURIComponent).join('=')
					// ).join('&')
					fetch('https://straightline-api.mudbank.uk/submit', {
						method: 'POST',
						body: formData
					})
						.then(resp => resp.json())
						.then(data => {
							const { success, suggested } = data
							setSubmitting(false)
							if (success) {
								navigate('/thanks/', {
									state: { suggested, image, body, about, recommendContent }
								})
							}
						})
						.catch(err => {
							console.error(err) // eslint-disable-line no-console
						})
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					isSubmitting
				}) => (
					<form
						method="post"
						acceptCharset="UTF-8"
						className="text-xs-fluid pb-2"
						onSubmit={handleSubmit}
						ref={this.form}
						noValidate
					>
						<div className="md:flex md:-mx-0-5 mb-1-25 lg:mb-1-5">
							<Field
								name="prison_number"
								text="prisoner number"
								type="text"
								id="prison_number"
								value={values.prison_number}
								error={errors.prison_number}
								touched={touched.prison_number}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<Field
								name="prisoner_name"
								text="prisoner name"
								type="text"
								id="prisoner_name"
								value={values.prisoner_name}
								error={errors.prisoner_name}
								touched={touched.prisoner_name}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</div>

						<div className="mb-1-25 lg:mb-1-5 md:mb-3">
							<Select
								items={prisons}
								label="location"
								name="prison"
								value={values.prison}
								error={errors.prison}
								touched={touched.prison}
								setFieldValue={setFieldValue}
							/>
						</div>

						<div className="md:flex md:-mx-0-5 mb-1-25 lg:mb-1-5">
							<Field
								name="name"
								id="name"
								text="your name"
								type="text"
								value={values.name}
								error={errors.name}
								touched={touched.name}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<Field
								name="email"
								text="your email"
								type="email address"
								id="email"
								value={values.email}
								error={errors.email}
								touched={touched.email}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</div>

						{!recording && (
							<div className="md:flex md:-mx-0-5 mb-1-25 lg:mb-1-5 lg:mb-2-5">
								<Field
									name="song"
									text="song"
									type="text"
									id="song"
									value={values.song}
									error={errors.song}
									touched={touched.song}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<Field
									name="artist"
									text="artist"
									type="text"
									id="artist"
									value={values.artist}
									error={errors.artist}
									touched={touched.artist}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</div>
						)}
						{!recording && (
							<div className="mb-2-25">
								<Textarea
									defaultValue=""
									name="message"
									id="message"
									text="message"
									value={values.message}
									error={errors.message}
									touched={touched.message}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</div>
						)}
						<div className="mb-2 relative">
							<input
								className="visuallyhidden o-checkbox"
								type="checkbox"
								id="confirm_permissions"
								name="confirm_permissions"
								required
								value={values.confirm_permissions}
								onChange={handleChange}
								onBlur={handleBlur}
								// value={confirm_permissions.value}
								// onChange={e => {
								// 	const { checked } = e.target
								// 	this.setState({
								// 		confirm_permissions: {
								// 			value: checked,
								// 			valid: checked,
								// 			message: checked ? '' : 'Please confirm permissions'
								// 		}
								// 	})
								// }}
							/>
							<label className="flex items-start" htmlFor="confirm_permissions">
								<span className="o-checkbox__button mr-0-5 border border-grey-light flex items-center justify-center  w-25 h-h5 mt-0-25">
									{' '}
									<BetterIcon icon={tick} className="icon w-10" />
								</span>
								<span className="flex-1">
									I confirm I have the permission of the person in prison to
									supply their details to National Prison Radio for the purposes
									of a dedication
								</span>
							</label>

							{errors.confirm_permissions && touched.confirm_permissions && (
								<div className="absolute font-bold pt-0-25 pin-b mt-1 y-100 text-ms--4 text-error">
									{errors.confirm_permissions}
								</div>
							)}
						</div>

						<Button
							type="submit"
							bg={['orange', 'teal']}
							className="text-xs-fluid w-full md:max-w-full"
							rounded={false}
							disabled={isSubmitting}
						>
							<span className="mr-1">submit song</span>
							<span>
								{isSubmitting ? (
									<ScaleLoader height={15} color="#fff" />
								) : (
									<Icon icon={rightArrowIcon} width="1em" />
								)}
							</span>
						</Button>
						{/* <input
								type="email"
								name="email"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
							/>
							{errors.email && touched.email && errors.email}
							<input
								type="password"
								name="password"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.password}
							/>
							{errors.password && touched.password && errors.password}
							<button type="submit" disabled={isSubmitting}>
								Submit
							</button> */}
					</form>
				)}
			</Formik>
		)
	}
}
